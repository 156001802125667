import { useGetSerialNumbersLazyQuery } from '@daisy/data-access';
import { Card, OnSelectChange, Select, SelectOption, ToastContent } from '@daisy/react-components';
import { debounce } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';

const MIN_VALID_SEARCH_LENGTH = 4;

const StyledDiv = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

export const SerialNumberSearchCardView = () => {
    const navigate = useNavigate();
    const { addToast } = useToasts();

    const [getSerialNumbersQuery] = useGetSerialNumbersLazyQuery();

    const handleSerialNumberSelectChange: OnSelectChange = useCallback(
        (option) => {
            if (option?.value) navigate(`/serial-number/${option?.value}/error-history-events`);
        },
        [navigate]
    );

    const handleLoadOptions = useMemo(
        () =>
            debounce((inputValue: string | null, callback: (options: SelectOption[]) => void) => {
                if (inputValue?.length && inputValue?.length >= MIN_VALID_SEARCH_LENGTH) {
                    getSerialNumbersQuery({
                        variables: { externalId: inputValue },
                        fetchPolicy: 'network-only',
                        onError: () => {
                            addToast(
                                <ToastContent
                                    type="error"
                                    description="Error on validating serial number"
                                />
                            );
                        },
                        onCompleted: ({ serialNumbers }) => {
                            if (!serialNumbers?.length) {
                                callback([]);
                                return;
                            }

                            if (serialNumbers?.length > 0) {
                                const newOptions = serialNumbers.map(({ externalId }) => ({
                                    label: externalId,
                                    value: externalId
                                }));
                                callback(newOptions);
                            }
                        }
                    });
                } else {
                    callback([]);
                }
            }, 5),
        [addToast, getSerialNumbersQuery]
    );

    return (
        <Card title="Serial number">
            <StyledDiv>
                <Select
                    name="serial_number"
                    placeholder="Search for serial number"
                    handleChange={handleSerialNumberSelectChange}
                    handleLoadOptions={handleLoadOptions}
                    isClearable
                    isAsync
                    noOptionsMessage={({ inputValue }) =>
                        inputValue.length < MIN_VALID_SEARCH_LENGTH
                            ? `Type at least ${MIN_VALID_SEARCH_LENGTH} characters.`
                            : 'No options'
                    }
                />
            </StyledDiv>
        </Card>
    );
};
