import { useAuth0 } from '@auth0/auth0-react';
import { UserInfoWeb } from '@daisy/middleware-redux';
import { IconButton } from '@daisy/react-components';
import { map } from 'lodash';
import { ReactElement, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useSpellbook } from '@/hooks/useSpellbook';
import { FeedbackModal } from '@/modals/FeedbackModal';
import { ErrorCodeView } from '@/views/ErrorCode/ErrorCodeView';
import { HomeView } from '@/views/HomeView';
import { NotFoundView } from '@/views/NotFoundView';
import { ReleaseNotesView } from '@/views/ReleaseNotesView';
import { SerialNumberView } from '@/views/SerialNumber/SerialNumberView';
import { TruckModelView } from '@/views/TruckModel/TruckModelView';
import packageJson from '../package.json';
import { Collapsible } from './components/Collapsible';
import { Header } from './components/Header';
import { MenuBar } from './components/MenuBar/MenuBar';
import { RouteGuard } from './components/RouteGuard';
import { TopBar } from './components/TopBar';
import { RouteContent, ViewContainer } from './components/styled/shared';
import { useFeatureFlags } from './hooks/useFeatureFlags';
import releaseNotes from './releaseNotes.json';
import { BusinessIntelligenceView } from './views/BusinessIntelligence/BusinessIntelligenceView';

const renderPaths = (paths: string[], element: ReactElement) =>
    map(paths, (path) => <Route key={path} path={path} element={element} />);

export const App = () => {
    const { pathname } = useLocation();
    const { sendApplicationEvent } = useSpellbook();
    const { user } = useAuth0<UserInfoWeb>();
    const featureFlags = useFeatureFlags();
     
    useEffect(() => {
        if (pathname) {
            sendApplicationEvent('Navigation', {
                eventName: 'Route change',
                eventMeta: {
                    route: pathname
                }
            }).catch((err: string) => console.error(err));
        }
    }, [pathname, sendApplicationEvent]);

    return (
        <ViewContainer>
            <Header>
                <TopBar user={user} versionText={packageJson.version} />
                <MenuBar />
            </Header>
            <div
                style={{ display: 'flex', position: 'relative', width: '100%' }}
                data-testid="main"
            >
                <RouteContent id="content">
                    <Routes>
                        <Route path="/" element={<HomeView />} />

                        {renderPaths(
                            [
                                '/truck-model/:truckModelId/:activeTab',
                                '/truck-model/:truckModelId/:activeTab/:activeSubTab'
                            ],
                            <TruckModelView />
                        )}

                        {renderPaths(
                            [
                                '/serial-number/:serialNumberId/:activeTab',
                                '/serial-number/:serialNumberId/:activeTab/:activeSubTab'
                            ],
                            <SerialNumberView />
                        )}

                        {renderPaths(
                            [
                                '/truck-model/:truckModelId/error-code/:errorCodeId',
                                '/serial-number/:serialNumberId/error-code/:errorCodeId'
                            ],
                            <ErrorCodeView />
                        )}

                        {featureFlags?.businessIntelligence && (
                            <Route
                                path="business-intelligence"
                                element={
                                    <RouteGuard permission="access:bi">
                                        <BusinessIntelligenceView />
                                    </RouteGuard>
                                }
                            />
                        )}

                        <Route
                            path="/release-notes/"
                            element={<ReleaseNotesView releaseNotes={releaseNotes} />}
                        />

                        <Route path="*" element={<NotFoundView />} />
                    </Routes>
                </RouteContent>
            </div>
            <Collapsible>
                {({ isOpen, toggleOpen }) => (
                    <>
                        <div
                            title="Give feedback"
                            style={{ position: 'fixed', bottom: '3em', right: '3em' }}
                        >
                            <IconButton
                                isPrimary
                                icon="feedback"
                                size="3.5em"
                                onClick={toggleOpen}
                            />
                        </div>
                        <FeedbackModal isOpen={isOpen} title="Send feedback" onClose={toggleOpen} />
                    </>
                )}
            </Collapsible>
        </ViewContainer>
    );
};
