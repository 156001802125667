import { useGetErrorCodesByInputLazyQuery } from '@daisy/data-access';
import { setSelectedEventCodes } from '@daisy/middleware-redux';
import { Modal, Multiselect, OnMultiselectChange, SelectOption } from '@daisy/react-components';
import { debounce, isEmpty, map, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OneColumn } from '@/components/styled/shared';
import { RootState, useAppDispatch } from '@/store';
import { ErrorCodeTruckSerialNumberSelect } from '@/views/ErrorCode/ErrorCodeTruckSerialNumberSelect';

type ErrorCodesModalProps = { handleModalClose: () => void };

function createErrorCodeSelectOptions(
    x: {
        userId: string;
        uuid: string;
        class: string;
    }[]
): SelectOption[] {
    return uniqBy(
        map(x, ({ userId, uuid }) => ({
            label: userId,
            value: uuid
        })),
        ({ label }) => label
    );
}

export const ErrorCodesModal: React.FC<ErrorCodesModalProps> = ({ handleModalClose }) => {
    const dispatch = useAppDispatch();
    const { selectedEventCodes } = useSelector((state: RootState) => state.truckModel);

    const [getErrorCodesByInput] = useGetErrorCodesByInputLazyQuery({
        fetchPolicy: 'no-cache'
    });

    const handleLoadOptions = useMemo(
        () =>
            debounce((inputValue: string | null, callback: (options: SelectOption[]) => void) => {
                if (inputValue?.length && inputValue?.length > 2) {
                    getErrorCodesByInput({
                        variables: { input: inputValue || '' },
                        onCompleted: (data) => {
                            const newOptions = createErrorCodeSelectOptions(
                                data?.eventsByInput || []
                            );
                            callback(newOptions);
                        }
                    });
                } else {
                    callback(createErrorCodeSelectOptions([]))
                }
            }, 5),
        [getErrorCodesByInput]
    );

    const selectValue = useMemo(
        () =>
            selectedEventCodes
                ? map(selectedEventCodes, (x) => ({
                      label: x.userId,
                      value: x.uuid
                  }))
                : null,
        [selectedEventCodes]
    );

    const handleSearchChange: OnMultiselectChange = (options) => {
        const selectedValue = !isEmpty(options)
            ? map(options, ({ label, value }) => ({
                  userId: label,
                  uuid: value as string
              }))
            : [];
        dispatch(setSelectedEventCodes(selectedValue));
    };

    return (
        <Modal
            isOpen
            title="Find error codes"
            actionButtonText="Select"
            onClose={handleModalClose}
            isOverflow
            dataTestId="error-codes-modal"
        >
            <OneColumn>
                <Multiselect
                    name="error_codes"
                    placeholder="Type to search for error codes"
                    value={selectValue}
                    onChange={handleSearchChange}
                    handleLoadOptions={handleLoadOptions}
                    isClearable
                    isAsync
                    inputId="error_code_input"
                    noOptionsMessage={({ inputValue }) =>
                        inputValue.length <= 2 ? 'Type at least 3 characters.' : 'No options'
                    }
                />
                {!!selectedEventCodes?.length && selectValue && (
                    <ErrorCodeTruckSerialNumberSelect callback={handleModalClose} />
                )}
            </OneColumn>
        </Modal>
    );
};
